<template>
  <div>
    <div class="Quotation">
      <div class="mainBox">
        <div class="form join Done">
          <a class="logo">
            <img src="@/assets/images/reviewmind/logo.png" alt="로고" />
          </a>
          <h2>구매해 주셔서 감사합니다.</h2>

          <!-- <h3 class="centerTxt">
            다시 이용해주세요.
            <br />
          </h3> -->
          <div class="btnWrap">
            <button class="btn full secondary" @click="submit">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submit() {
      window.location.href = "/";
    },
  },
};
</script>
